<template>
  <article class="media comment">
    <div class="media-content">
      <p>
        <strong v-text="comment.author"></strong>&nbsp;
          <a v-if="comment.username" :href="comment.link" target="_blank">@{{comment.username}}&nbsp;</a>
         <span class="icon icon-clock"><i class="far fa-clock"></i></span>{{ comment.displayDate | moment }}
        <br />
      </p>
      <div class="has-spacing-bottom" v-html="comment.comment"></div>
      <nav class="level is-mobile">
        <div class="level-left">
          <span class="level-item is-size-6"><span>{{comment.likes}} <span>{{ comment.likes === 1 ? 'Like' : 'Likes' }}</span></span>&nbsp;</span>
          <a class="level-item is-size-6" @click.prevent="toggleLike(comment.id)" >
            <span class="icon is-small icon-like">
                <i class="fas fa-heart"></i>
            </span>
            <span>{{comment.isLiked ? 'Unlike' : 'Like'}}</span>
          </a>
          <!-- <a class="level-item">
            <span class="icon is-small">
                <i class="fas fa-reply"></i>
            </span>&nbsp; Reply
          </a> -->
        </div>
      </nav>
    </div>
  </article>
</template>
<script>
import eventBus from '../functions/event-bus'
import * as moment from 'moment'
moment.locale('en', {
  calendar: {
    sameElse: 'DD MMM, YYYY'
  }
})

export default {
  name: 'comment',
  props: ['comment'],
  methods: {
    toggleLike (id) {
      eventBus.$emit('TOGGLE_LIKE', id)
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).calendar()
    }
  }
}
</script>
