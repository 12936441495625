class MobileMenu {
  constructor (selector) {
    this.element = document.querySelector(selector)
    this.navActiveClass = 'is-active'
    this.$navbarBurgers = Array.prototype.slice.call(this.element.querySelectorAll('.navbar-burger'), 0)
    this.$navbarMenu = this.element.querySelector('#navbarMenu')

    document.addEventListener('DOMContentLoaded', () => {
      if (typeof this.callback === 'function') {
        this.callback()
      }
    })
  }

  onReady (callback) {
    this.callback = callback
  }

  init () {
    if (this.$navbarBurgers.length > 0) {
      this.$navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          const target = el.dataset.target
          const $target = document.getElementById(target)
          el.classList.toggle(this.navActiveClass)
          $target.classList.toggle(this.navActiveClass)
        })
      })
    };
    window.addEventListener('resize', () => {
      this.resetNavs()
    })
  }

  resetNavs () {
    for (var i = 0; i < this.$navbarBurgers.length; i++) {
      this.$navbarBurgers[i].classList.remove(this.navActiveClass)
    }
    this.$navbarMenu.classList.remove(this.navActiveClass)
  }
}

export default MobileMenu
