<template>

  <div id="comments" class="box has-background-white has-spacing-top content comment-form" v-cloak>
    <h3 class="title is-size-5" v-if="comments">Comments</h3>
    <comment v-for="item in comments" :comment="item" :key="item.id" />
    <transition name="fade" mode="out-in">
        <article key="form-initial" v-if="!submitted" class="media" >
            <div class="media-content">
              <h4 class="title is-size-5">Leave a comment</h4>
              <form novalidate="novalidate" @submit.prevent="validateBeforeSubmit">
                  <div class="field">
                    <p class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Name"
                        name="Name"
                        v-model="commentRequest.name"
                        v-validate="'required'"
                        novalidate="novalidate"
                      />
                    </p>
                    <span
                      class="field-validation-error"
                      v-if="errors.has('Name')"
                    >{{errors.first('Name')}}</span>
                  </div>
                  <div class="field">
                    <p class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Twitter username (optional)"
                        name="Username"
                        v-model="commentRequest.username"
                        novalidate="novalidate"
                      />
                    </p>
                  </div>
                  <div class="field">
                    <p class="control">
                      <textarea
                        class="textarea input"
                        placeholder="Add a comment..."
                        name="Content"
                        v-model="commentRequest.content"
                        v-validate="'required'"
                        novalidate="novalidate"
                      ></textarea>
                    </p>
                    <span
                      class="field-validation-error"
                      v-if="errors.has('Content')"
                    >{{errors.first('Content')}}</span>
                  </div>
                  <div class="field">
                    <p class="control">
                      <button class="button is-primary button" :class="{ 'is-loading': isLoading }">Post Comment</button>
                    </p>
                  </div>
              </form>
            </div>
        </article>
        <div key="form-submitted" v-if="submitted" class="media">
            <div class="media-content has-text-centered">
              <h4 class="title is-size-4">{{commentsFormContent.submittedTitle}}</h4>
              <div class="is-size-5" v-html="commentsFormContent.submittedText"></div>
          </div>
      </div>
    </transition>

  </div>
</template>

<script>
import axios from 'axios'
import comment from '../components/comment.vue'
import eventBus from '../functions/event-bus'

export default {
  name: 'comments-section',
  components: {
    comment
  },
  data () {
    return {
      isLoading: false,
      submitted: false,
      comments: {},
      api: '/Umbraco/Api/Forms/SubmitCommentForm',
      likeApi: '/Umbraco/Api/Forms/SubmitLike',
      commentRequest: {
        name: '',
        username: '',
        content: '',
        pageId: 0
      },
      commentsFormContent: {}
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validate().then(result => {
        if (result) {
          this.handleSubmit()
        }
      })
    },
    handleSubmit () {
      var self = this
      self.isLoading = true
      axios
        .post(this.api, this.commentRequest, {
          headers: { 'Content-Type': 'application/json' }
          // headers: { 'Content-Type': 'text/plain;charset=utf-8' }
        })
        .then(function (response) {
          self.isLoading = false
          self.submittedError = false
          self.submittedForm()
        })
        .catch(function () {
          // console.log(error)
          self.isLoading = false
          self.submittedError = true
          self.submittedForm()
        })
    },
    submittedForm () {
      this.$data.submitted = true
    },
    submitLike () {
      // console.log('like submitted')
    },
    toggleLike (id) {
      // deal with the likes
      const objIndex = this.$data.comments.findIndex(obj => obj.id === id)

      if (this.$data.comments[objIndex].isLiked) {
        this.$data.comments[objIndex].likes--
      } else {
        this.$data.comments[objIndex].likes++
      }

      this.$data.comments[objIndex].isLiked = !this.$data.comments[objIndex].isLiked

      axios
        .post(this.likeApi,
          {
            id: id,
            isLiked: this.$data.comments[objIndex].isLiked
          },
          {
            headers: { 'Content-Type': 'application/json' }
          })
        .then(function (response) {
          // console.log('liked')
        })
        .catch(function () {
          // console.log(error)
        })
    }
  },
  beforeMount () {
    this.comments = window.jacker.comments
    this.commentsFormContent = window.jacker.commentsFormContent
    this.commentRequest.pageId = window.jacker.pageId
  },
  created () {
    eventBus.$on('TOGGLE_LIKE', id => {
      this.toggleLike(id)
    })
  }
}
</script>
