/* eslint-disable no-console */
import './scss/main.scss'
import MobileMenu from './scripts/MobileMenu'
import SubscribeForm from './apps/SubscribeForm'
import CommentsSection from './apps/CommentsSection'
import Vue from 'vue'
import VeeValidate from 'vee-validate'
// import { required } from 'vee-validate/dist/rules';
import Glide from '@glidejs/glide'
import Aos from 'aos'

Vue.config.productionTip = false
Vue.use(VeeValidate, {
  events: 'change'
})

// subscribe form
const subscribeForm = document.querySelector('#subscribeForm')
if (subscribeForm) {
  new Vue({
    render: h => h(SubscribeForm)
  }).$mount('#subscribeForm')
}

// comments section with form
const commentsSection = document.querySelector('#commentsSection')
if (commentsSection) {
  new Vue({
    render: h => h(CommentsSection)
  }).$mount('#commentsSection')
}

// mobile menu
var mobileMenu = new MobileMenu('#navbarMain')
mobileMenu.onReady(() => { mobileMenu.init() })

// glide slider
var sliders = document.querySelectorAll('.glide')

for (var i = 0; i < sliders.length; i++) {
  var glide = new Glide(sliders[i], {
    autoplay: 4000,
    type: 'carousel'
  })
  glide.mount()
}

// animate on scroll
Aos.init({
  once: true
})

document.addEventListener('DOMContentLoaded', () => {
  // console.log('Jacker.io - Umbraco themes, extensions and tutorials made with LOVE.')
})
