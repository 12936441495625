<template>
   <form
    class="tile is-child notification is-light"
    novalidate="novalidate"
    @submit.prevent="validateBeforeSubmit">
    <transition name="fade" mode="out-in">
      <div key="form-initial" v-if="!submitted" >
        <p class="title is-4">{{formContent.formTitle}}</p>
        <div class="field">
            <validation-provider>
            <div class="control has-icons-left has-icons-right">
                <input
                  class="input is-medium"
                  type="text"
                  placeholder="Name"
                  name="Name"
                  v-model="newsletterRequest.name"
                  v-validate="'required'"
                  novalidate="novalidate"
                  >
                <span class="icon is-left">
                    <i class="fas fa-user fa-sm"></i>
                </span>
                <span class="icon is-right">
                    <i class="fas fa-check fa-sm"></i>
                </span>
            </div>
            <span class="field-validation-error" v-if="errors.has('Name')">{{errors.first('Name')}}</span>
            </validation-provider>
        </div>
        <div class="field">
            <div class="control has-icons-left has-icons-right">
                <input
                  class="input is-medium"
                  type="email"
                  name="Email"
                  placeholder="Email"
                  v-model="newsletterRequest.email"
                  v-validate="'required|email'"
                  novalidate="novalidate"
                  >
                <span class="icon is-left">
                    <i class="fas fa-envelope fa-sm"></i>
                </span>
                <span class="icon is-right">
                    <i class="fas fa-check fa-sm"></i>
                </span>
            </div>
            <span class="field-validation-error" v-if="errors.has('Email')">{{ errors.first('Email') }}</span>
        </div>
        <div class="field"><p>No spam of course.</p></div>
        <div class="field">
            <div class="control">
                <button type="submit" class="is-primary button" :class="{ 'is-loading': isLoading }">Subscribe To Updates</button>
            </div>
        </div>
      </div>
      <div key="form-submitted" v-if="submitted" class="content">
          <div class="media">
            <div class="media-content">
              <h4 class="title is-size-3">{{formContent.submittedTitle}}</h4>
            </div>
            <div v-if="formContent.submittedImage" class="media-right">
              <span class="image pull-right">
                <img class="is-circle" :src="formContent.submittedImage">
              </span>
            </div>
          </div>
          <div class="is-size-5" v-html="formContent.submittedText"></div>
      </div>
    </transition>
    </form>
</template>

<script>
import axios from 'axios'

export default {
  name: 'subscribe-form',
  data () {
    return {
      isLoading: false,
      submitted: false,
      formContent: {},
      api: '/Umbraco/Api/Forms/SubmitNewsletterForm',
      newsletterRequest: {
        name: '',
        email: ''
      }
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validate().then(result => {
        if (result) {
          this.handleSubmit()
        }
      })
    },
    handleSubmit () {
      var self = this
      self.isLoading = true

      axios
        .post(this.api, this.newsletterRequest, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(function (response) {
          self.isLoading = false
          self.submittedError = false
          self.submittedForm()
        })
        .catch(function () {
          // console.log(error)
          self.isLoading = false
          self.submittedError = true
          self.submittedForm()
        })
    },
    submittedForm () {
      this.$data.submitted = true
    }
  },
  beforeMount () {
    this.formContent = window.jacker.form
  }
}
</script>
